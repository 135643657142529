import { render, staticRenderFns } from "./LoyaltyUserProfile.vue?vue&type=template&id=bac6e468&scoped=true&"
import script from "./LoyaltyUserProfile.vue?vue&type=script&lang=js&"
export * from "./LoyaltyUserProfile.vue?vue&type=script&lang=js&"
import style0 from "./LoyaltyUserProfile.vue?vue&type=style&index=0&id=bac6e468&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "bac6e468",
  null
  
)

export default component.exports