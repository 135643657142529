<template>
  <div ref="container"
    class="q-experience-indicator"
    :style="objectStyle">

    <IconBase
      class="icon"
      v-for="i in numberOfIcons"
      v-bind:key="i"
      :width="iconWidth"
      :height="iconHeight"
      :icon-name="i"
      :iconColor="backgroundColor"
      >

      <QIndicator />

    </IconBase>
  </div>
</template>

<script>
import ResizableElementMixin from '@paiuolo/pai-vue-mixins/mixins/ResizableElementMixin'

import IconBase from '../../elements/icons/IconBase'
import QIndicator from '../../elements/icons/paths/QIndicator'

export default {
  name: 'QExperienceIndicator',
  mixins: [
    ResizableElementMixin
  ],
  components: {
    IconBase,
    QIndicator
  },
  props: {
    numberOfIcons: {
      type: Array,
      default () {
        return [
          'uno',
          'due',
          'tre',
          'quattro',
          'cinque'
        ]
      }
    },
    ratio: {
      type: Number,
      default: 0.2
    },
    experience: {
      type: [String, Number],
      default: 0
    },
    count: {
      type: [String, Number],
      default: 0
    },
    maxExperience: {
      type: [String, Number],
      default: 1
    },
    backgroundColor: {
      type: String,
      default: '#fff'
    }
  },
  data () {
    return {
      width: 500,
      height: 100,
      experienceLevel: this.experience ? this.experience : this.calcExpLevel(this.count)
    }
  },
  computed: {
    iconWidth () {
      return this.width / this.numberOfIcons.length
    },
    iconHeight () {
      return this.height
    },
    objectStyle () {
      return {
        'background-size': '' + ((this.experienceLevel * 100) / this.maxExperience) + '% 100%'
      }
    }
  },
  methods: {
    calcExpLevel (events) {
      return Math.tanh(events/493)
    }
  },
  watch: {
    experience (n) {
      this.experienceLevel = n
    },
    count (n) {
      this.experienceLevel = this.calcExpLevel(n)
    }
  }
}
</script>

<style lang="scss" scoped>
@import "../../../scss/theme";

.q-experience-indicator {
  width: 100%;
  padding: 0;
  overflow: hidden; 
  text-overflow: ellipsis;
  white-space: nowrap;

  transition: background-size 1s;
  background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAIAAACQd1PeAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAB3RJTUUH4goaDQkrl+puKQAAABl0RVh0Q29tbWVudABDcmVhdGVkIHdpdGggR0lNUFeBDhcAAAAMSURBVAjXY2DYtwQAAiQBY/8xKpsAAAAASUVORK5CYII=');

  background-repeat: repeat-y;
  background-position: 0;
  background-size: 0% 100%;
  
  box-sizing: border-box;
  border: 4px solid #fff;
  border-radius: 4px;

  background-color: #dedede;
  box-shadow:0px 0px 0px 1px #fff inset;
}
</style>
