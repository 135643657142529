<template>
  <div
    ref="container"
    class="qpost-list"
    :class="{dark: dark}"
    >

    <LoadingBox
      :dark="dark"
      v-if="loading"
      class="loading-box">
    </LoadingBox>
    
    <div class="element card"
      v-for="(item, key) in entities"
      :key="key">

      <i class="icon material-icons"
        :style="getEntityIconStyle(item)">
        {{getEntityIcon(item)}}
      </i>

      <h4 class="text">
        <a :href="getEntityUrl(item)" alt="item" target="_blank">
         <span class="date"
          v-if="item.created_at">{{getEntityDate(item)}} - </span>
          {{getEntityTitle(item)}}
        </a>
      </h4>
    </div>

    <div class="clear">
      <a v-if="maxFound > 0 && (loadFrom + parseInt(size)) < maxFound"
        class="load-more"
        @click="loadMore">
        {{ gettext('Load more') }}
      </a>
    </div>

  </div>
</template>

<script>
import moment from 'moment'

import UserEventsMixin from '../event/UserEventsMixin'

import LoadingBox from '../../elements/loader/LoadingBox'

export default {
  name: 'QpostList',
  mixins: [
    UserEventsMixin
  ],
  components: {
    LoadingBox
  },
  props: {
    dark: {
      type: Boolean,
      default: false
    },
    uscita: {
      type: String,
      default: null
    },
    eventTypes: {
      type: String,
      default: 'qpost_verde,qpost_giallo,qpost_grigio,qpost_rosso,qpost_rosso_ticket'
    }
  },
  data () {
    return {}
  },
  methods: {
    clear () {
      this.loadFrom = this.from
      this.maxFound = 0
      this.entities = []
    },
    entityClicked (e) {
      this.$emit('entityClicked', e)
    },
    getEntityUrl (e) {
      return e.qcumber_permalink || null
    },
    getEntityIcon () {
      return 'place'
    },
    getEntityTitle (e) {
      let text = e.text
      let chars = 140
      if (text.length > chars) {
        text = text.substr(0, chars)
        let lastIndex = text.lastIndexOf(' ')
        text = text.substring(0, lastIndex) + ' ...'
      }
      return text
    },
    getEntityIconStyle (e) {
      let style = {}
      let color = null
      if(e.type === 'qpost_rosso' || e.type === 'qpost_rosso_ticket') {
        color = '#c53c35'
      } else if ( e.type === 'qpost_verde') {
        color = '#7eb950'
      } else if ( e.type === 'qpost_grigio') {
        color = '#9d9e9f'
      } else if ( e.type === 'qpost_giallo') {
        color = '#f2c556'
      }
      if (color) {
        style['color'] = color
      }
      return style
    },
    getEntityDate (e) {
      let date = new Date(e.timestamp)
      return moment(date).calendar()
    },
    parseEntities (data) {
      this.maxFound = data.hits.total.value

      let e = data.hits.hits.map((e) => {
        return e['_source']['doc']
      })

      if (this.loadFrom > 0) {
        e = this.entities.concat(e)
      }
      return e
    }
  }
}
</script>

<style lang="scss" scoped>
@import "../../../scss/theme";

.qpost-list {
  position: relative;
  width: 100%;
  box-sizing: border-box;
  overflow: hidden;
  min-height: 72px;

  margin: 0;
  padding: 0;

  a {
    color: #111;
    text-decoration: underline;
  }

  .clear {
    box-sizing: border-box;
    padding: 1em 0;
    clear: both;
    text-align: center;

    .load-more {
      cursor: pointer;
      font-weight: bold;
      text-decoration: underline;
    }
  }

  .card {
    position: relative;
    overflow: hidden;

    .icon {
      display: inline-block;
      float: left;
      margin: 0 12px 12px 0;
      font-size: 24px;
    }
    .date {
      line-height: 24px;
    }
    .text {
      margin: 0 12px 12px 0;
    }
  }
}
.qpost-list.dark {
  color: #fff;

  a {
    color: #fff;
  }
}
</style>
