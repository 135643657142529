<template>
  <div
    ref="container"
    class="qpost-counter-extended"
    :style="styleObject"
    >

    <div class="counter verde"
      @click="itemClicked('qpost_verde')">
      <span>{{d_qpost_verde}}</span>
    </div>
    <div class="counter giallo"
      @click="itemClicked('qpost_giallo')">
      <span>{{d_qpost_giallo}}</span>
    </div>
    <div class="counter rosso"
      @click="itemClicked('qpost_rosso')">
      <span>{{d_qpost_rosso}}</span>
    </div>
    <div class="counter grigio"
      @click="itemClicked('qpost_grigio')">
      <span>{{d_qpost_grigio}}</span>
    </div>
    <div class="counter rosso_ticket"
      @click="itemClicked('qpost_rosso_ticket')">
      <span>{{d_qpost_rosso_ticket}}</span>
    </div>

    <div class="clear"></div>
  </div>
</template>

<script>
import ResizableElementMixin from '@paiuolo/pai-vue-mixins/mixins/ResizableElementMixin'

import UserEventsMixin from '../event/UserEventsMixin'


export default {
  name: 'QpostCounter2',
  mixins: [
    ResizableElementMixin,
    UserEventsMixin
  ],
  props: {
    ratio: {
      type: Number,
      default: 0.196
    },
    sfAction: {
      type: String,
      default: 'count'
    },
    qpost_verde: {
      type: [String, Number],
      default: 0
    },
    qpost_giallo: {
      type: [String, Number],
      default: 0
    },
    qpost_rosso: {
      type: [String, Number],
      default: 0
    },
    qpost_grigio: {
      type: [String, Number],
      default: 0
    },
    qpost_rosso_ticket: {
      type: [String, Number],
      default: 0
    },
    eventTypes: {
      type: String,
      default: 'qpost_verde,qpost_giallo,qpost_grigio,qpost_rosso,qpost_rosso_ticket'
    }
  },
  data () {
    return {
      width: 600,
      height: 118,

      d_qpost_verde: this.qpost_verde,
      d_qpost_giallo: this.qpost_giallo,
      d_qpost_rosso: this.qpost_rosso,
      d_qpost_grigio: this.qpost_grigio,
      d_qpost_rosso_ticket: this.qpost_rosso_ticket
    }
  },
  computed: {
    styleObject () {
      return {
        'line-height': (this.height) + 'px',
        fontSize: (this.height / 5) + 'px'
      }
    }
  },
  methods: {
    resize () {
      let w = this.$refs.container.clientWidth
      let h = w * this.ratio
      this.width = w
      this.height = h
    },
    itemClicked (slug) {
      this.$emit('selected', slug)
    },
    parseEntities (data) {
      let buckets = data.aggregations.group_by_type.buckets
      if (buckets.length) {
        this.d_qpost_verde = this.getBucketCount(buckets, 'qpost_verde')
        this.d_qpost_giallo = this.getBucketCount(buckets, 'qpost_giallo')
        this.d_qpost_rosso = this.getBucketCount(buckets, 'qpost_rosso')
        this.d_qpost_grigio = this.getBucketCount(buckets, 'qpost_grigio')
        this.d_qpost_rosso_ticket = this.getBucketCount(buckets, 'qpost_rosso_ticket')
      }

      return data.hits.hits.map((e) => {
        return e['_source']
      })
    }
  },
  watch: {
    qpost_verde (n) {
      this.d_qpost_verde = n
    },
    qpost_giallo (n) {
      this.d_qpost_giallo = n
    },
    qpost_rosso (n) {
      this.d_qpost_rosso = n
    },
    qpost_grigio (n) {
      this.d_qpost_grigio = n
    },
    qpost_rosso_ticket (n) {
      this.d_qpost_rosso_ticket = n
    }
  }
}
</script>

<style lang="scss" scoped>
@import "../../../scss/theme";

.qpost-counter-extended {
  position: relative;
  width: 100%;

  margin: 0;
  padding: 0;
  box-sizing: border-box;
  overflow: hidden;

  .counter {
    margin: 0;
    padding: 0;
    text-align: center;
    color: #fff;
    letter-spacing: 0.1em;
    width: 20%;
    float: left;
    display: inline-block;
    font-weight: bold;
    background-size: 100% auto;
    background-position-y: 61%;
    background-position-x: 0;
    background-repeat: no-repeat;
    cursor: pointer;
  }
  .counter.verde {
    background-image: url('../../../assets/entities/qpost/qpost_verde.png');
  }
  .counter.giallo {
    background-image: url('../../../assets/entities/qpost/qpost_giallo.png');
  }
  .counter.rosso {
    background-image: url('../../../assets/entities/qpost/qpost_rosso.png');
  }
  .counter.grigio {
    background-image: url('../../../assets/entities/qpost/qpost_grigio.png');
  }
  .counter.rosso_ticket {
    background-image: url('../../../assets/entities/qpost/qpost_rosso_ticket.png');
  }
}
</style>
