/**
 * @mixin
 */

import SsoUserElementMixin from '@paiuolo/django-sso-vue-mixins/mixins/SsoUserElementMixin'

import EntityListMixin from '@paiuolo/pai-vue-mixins/mixins/EntityListMixin'
import xhrGetMixin from '@paiuolo/pai-vue-mixins/mixins/xhrGetMixin'


export default {
  mixins: [
    EntityListMixin,
    xhrGetMixin,
    SsoUserElementMixin
  ],
  props: {
    auto: {
      type: Boolean,
      default: false
    },
    userProfileUrl: {
      type: String,
      default: 'https://accounts.q-cumber.org/api/v1/auth/user/'
    },
    loginUrl: {
      type: String,
      default: 'https://accounts.q-cumber.org/login/'
    },
    eventTypes: {
      type: String,
      default: null
    },
    sfUrl: {
      type: String,
      default: 'https://events.q-cumber.org/serverless-functions/get-user-events/'
    },
    sfAction: {
      type: String,
      default: 'get'
    },
    size: {
      type: [String, Number],
      default: 10
    },
    from: {
      type: [String, Number],
      default: 0
    },
    userId: {
      type: String,
      default: null
    }
  },
  data () {
    return {
      eventTypesD: this.eventTypes ? this.eventTypes.split(',').map((el)=>{return el.replace(/ /g, '')}) : [], // ['qpost_verde', 'qpost_giallo', 'qpost_grigio', 'qpost_rosso', 'qpost_rosso_ticket'],
      loadFrom: this.from,
      maxFound: 0,

      xhrGetParams: {
        withCredentials: true
      },
      xhrGetPermittedErrors: '502,403',
      xhrGetMaxRetries: 5,
      xhrGetRetryTimeout: 199,
      xhrGetRetryOnPermittedError: true,
      apiError: false,
      notAuthorized: false
    }
  },
  mounted () {
    this.$on('performingXhrGet', () => {
//       console.log('performingXhrGet')
      this.apiError = false
      this.startLoading()

      this.$emit('performingGetEntities')
    })
    this.$on('xhrGetResponse', (res) => {
//       console.log('xhrGetResponse')
      this.entities = this.parseEntities(res.data)

      this.$emit('getEntitiesSuccess', res.data)
      this.stopLoading()
    })
    this.$on('xhrGetError', (err) => {
//       console.log('xhrGetError', err)
      this.apiError = true

      this.$emit('getEntitiesError', err)
      this.stopLoading()
    })

    if (this.auto) {
      this.getEntities()
    }
  },
  methods: {
    getBucketCount (B, s) {
      for (let i=0; i<B.length; i++) {
        let b = B[i]
        if (b.key === s) {
          return b.doc_count
        }
      }
      return 0
    },
    parseEntities (data) {
      this.maxFound = data.hits.total.value

      let e = data.hits.hits.map((e) => {
        return e['_source']
      })

      if (this.loadFrom > 0) {
        e = this.entities.concat(e)
      }

      return e
    },
    getEntities () {
      if (this.userProfile) {
        this.xhrGetUrl = this.sfUrl
        this.xhrGetUrl += '?action=' + this.sfAction
        this.xhrGetUrl += '&event_types=' + this.eventTypesD.join(',')
        this.xhrGetUrl += '&size=' + this.size

        if (this.userId) this.xhrGetUrl += '&user_id=' + this.userId
        if (this.loadFrom > 0) this.xhrGetUrl += '&from=' + this.loadFrom

//       console.log('getEntities', this.xhrGetUrl)
        this.performXhrGet()
      } else {
        this.fetchUserProfile()
      }
    },
    loadMore () {
      this.loadFrom += parseInt(this.size)
      this.getEntities()
    }
  },
  watch: {
    auto (n) {
      if (n) {
        this.getEntities()
      }
    },
    userId (n) {
      if (n && this.auto) {
        this.getEntities()
      }
    },
    userProfile (n) {
      if (n && this.auto) {
        this.loadFrom = 0
        this.setLocale(n.language)

        this.getEntities()
      }
    },
    eventTypes (n) {
      if (n) {
        this.eventTypesD = n.split(',').map((el)=>{return el.replace(/ /g, '')})

        if (this.auto) {
          this.loadFrom = 0
          this.getEntities()
        }
      }
    }
  }
}
