<template>
  <div class="loginRequiredMask" v-if="!loggedIn && !alreadyLoggedIn">
    <slot>
      <LoyaltyMdcButton
        @click="performLogin"
        >
        {{tLoginText}}
      </LoyaltyMdcButton>
    </slot>
  </div>
</template>

<script>
import LoginMixin from '@paiuolo/django-sso-vue-mixins/mixins/LoginMixin'

import LoyaltyMdcButton from '../../elements/button/loyalty/LoyaltyMdcButton'

export default {
  name: 'LoginRequiredMask',
  mixins: [
    LoginMixin
  ],
  components: {
    LoyaltyMdcButton
  },
  props: {
    loginText: {
      type: String,
      default: 'Login'
    }
  },
  computed: {
    tLoginText () {
      return this.gettext(this.loginText)
    }
  }
}
</script>

<style lang="scss" scoped>
@import "../../../scss/theme";
@import "../../../scss/flexbox";

.flex-center-y {
  align-items: center;
  justify-content: center;
}

.loginRequiredMask {
  @include flexbox();
  @include flex-direction(row);

  align-items: center;
  justify-content: center;

  position: absolute;
  z-index: 3;

  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  background-color: rgba(255, 255, 255, 0.65);
}

</style>
