<template>
  <div class="mdc-layout-grid loyalty-user-profile">

    <login-required-mask
      v-if="!hideOver && !loading"
      :already-logged-in="userProfile !== null"
      :login-url="loginUrl"
      >
    </login-required-mask>

    <loading-box
      v-if="loading"
      class="loading-box">
    </loading-box>

    <div class="mdc-layout-grid__inner">
      <div class="mdc-layout-grid__cell mdc-layout-grid__cell--span-3-desktop mdc-layout-grid__cell--span-8-tablet mdc-layout-grid__cell--span-12-phone">
        <div class="picture" v-bind:style="pictureStyle">
        </div>
      </div>
      <div class="mdc-layout-grid__cell mdc-layout-grid__cell--span-3-desktop mdc-layout-grid__cell--span-8-tablet mdc-layout-grid__cell--span-12-phone">
        <div class="info">
          <h4>
            <label>{{usernameString}}</label>
            <big>{{userProfileUsername || '..'}}</big>
          </h4>

          <div class="experience">
            <h4>{{experienceString}}</h4>

            <QExperienceIndicator
                ref="experienceIndicator"
                class="indicator"
                :count="qpostCount"
                background-color="#fff"
              >
            </QExperienceIndicator>
          </div>
        </div>
      </div>
      <div class="mdc-layout-grid__cell mdc-layout-grid__cell--span-6-desktop mdc-layout-grid__cell--span-8-tablet mdc-layout-grid__cell--span-12-phone">
        <h3 class="titolo segnalazioni">
          {{gettext('Your reports on Q-cumber')}}
        </h3>

        <QpostCounter2
          :auto="userProfile !== null"
          :sf-url="sfUrl"

          @selected="detailSelected"
          @getEntitiesSuccess="getEntitiesSuccess"
          >
        </QpostCounter2>
      </div>
    </div>

    <div class="eventDetail" :class="{opened: (selectedEvent !== null)}">
      <div class="content" v-if="selectedEvent">
        <i class="material-icons"
          @click="selectedEvent = null"
        >close</i>

        <h2>{{selectedEvent.name}}</h2>

        <div class="detail">
          <div class="description" v-html="selectedEvent.description"></div>

          <QpostList
            v-if="segnalazioniQt(selectedEvent.slug) > 0"
            :auto="segnalazioniQt(selectedEvent.slug) > 0"
            dark
            sf-action="search"
            size="3"
            :sf-url="sfUrl"
            :event-types="selectedEvent.slug">
          </QpostList>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import UserEventsMixin from '../../event/UserEventsMixin'

import QExperienceIndicator from '../../user/QExperienceIndicator'

import LoadingBox from '../../../elements/loader/LoadingBox'
import LoginRequiredMask from '../../user/LoginRequiredMask'
import QpostCounter2 from '../../qpost/QpostCounter2'
import QpostList from '../../qpost/QpostList'

export default {
  name: 'LoyaltyUserProfile',
  mixins: [
    UserEventsMixin
  ],
  components: {
    LoadingBox,
    LoginRequiredMask,
    QpostCounter2,
    QExperienceIndicator,
    QpostList
  },
  props: {
    hideOver: {
      type: Boolean,
      default: false
    },
    sfAction: {
      type: String,
      default: 'count'
    },
    eventTypes: {
      type: String,
      default: 'qpost_verde,qpost_giallo,qpost_grigio,qpost_rosso,qpost_rosso_ticket'
    }
  },
  data () {
    return {
      selectedEvent: null,
      usernameString: this.gettext('Username'),
      experienceString: this.gettext('Experience'),
      qpostCount: 0,
      
      events: {
        qpost_verde: {
          'slug': 'qpost_verde',
          'name': 'QPost Verde',
          'description': 'Il Post verde rappresenta una segnalazione di un particolare elemento di pregio e bellezza del territorio, da preservare e tutelare.',
          qt: 0
        },
        qpost_giallo: {
          'slug': 'qpost_giallo',
          'name': 'QPost Giallo',
          'description': 'Il Post giallo rappresenta una segnalazione di uno o più articoli di giornale relativi al territorio.',
          qt: 0
        },
        qpost_rosso: {
          'slug': 'qpost_rosso',
          'name': 'QPost Rosso',
          'description': 'Il Post rosso rappresenta una segnalazione di una problematica rilevata sul territorio (es. abbandono di rifiuti) che il cittadino intende condividere.',
          qt: 0
        },
        qpost_grigio: {
          'slug': 'qpost_grigio',
          'name': 'QPost Grigio',
          'description': 'Il Post grigio rappresenta una segnalazione di documenti e link relativi al territorio.',
          qt: 0
        },
        qpost_rosso_ticket: {
          'slug': 'qpost_rosso_ticket',
          'name': 'QPost Rosso Notificato',
          'description': 'Il Post rosso notificato rappresenta una segnalazione di una problematica rilevata sul territorio (es. abbandono di rifiuti) che il cittadino intende condividere con le istituzioni.',
          qt: 0
        }
      }
    }
  },
  mounted () {
  },
  computed: {
    pictureStyle () {
      let style = {}
      if (this.userProfile) {
        if (this.userProfile.picture) {
          style['background-image'] = 'url(\'' + this.userProfile.picture + '\')'
        }
      }
      return style
    },
    segnalazioniSum () {
      return (this.events.qpost_verde.qt 
        + this.events.qpost_rosso.qt 
        + this.events.qpost_giallo.qt
        + this.events.qpost_grigio.qt
        + this.events.qpost_rosso_ticket.qt)
    }
  },
  methods: {
    segnalazioniQt (s) {
      if (this.events[s]) {
        return this.events[s].qt
      }
      return 0
    },
    detailSelected (s) {
      this.selectedEvent = this.events[s]
    },
    getUserExperience () {
      this.$refs.experienceIndicator.getUserExperience()
    },
    getEntitiesSuccess (data) {
      this.qpostCount = data.hits.total.value
      let buckets = data.aggregations.group_by_type.buckets
      for (let i=0; i<buckets.length; i++) {
        let b = buckets[i]
        this.events[b.key].qt = b.doc_count
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import "../../../../scss/loyalty.theme";

$mdc-layout-grid-default-gutter: ( desktop: 0px, tablet: 0px, phone: 0px );
$mdc-layout-grid-default-margin: ( desktop: 0px, tablet: 0px, phone: 0px );

@import "@material/layout-grid/mdc-layout-grid";

.loyalty-user-profile {

  position: relative;
  margin-top: 48px;
  padding: 0;
  padding-top: 16px;
  color: #444;
  background-color: #fafafa;

  label {
    margin: 0 16px 16px 0
  }

  h3, label {
    color: #63ba9f;
  }

  h3 {
    margin: 16px 0 8px;
  }

  .info {
    position: relative;

    h3 {
      margin-bottom: 16px
    }
    .experience {
      margin: auto;
      padding-bottom: 16px;
      max-width: 220px;
      
      h4 {
        margin: 0 0 16px 0;
        color: #63ba9f;
      }
      .indicator {
        margin: auto;
      }

      @media screen and (min-width: $break-tablet) {
        margin: 0;
        padding-bottom: 36px;
      }
    }
  }

  .picture {
    margin: 0 auto;
    border-radius: 16px;
    border: 3px solid #fff;
    position: relative;
    top: -42px;
    margin-bottom: -42px;
    background-repeat: no-repeat;
    background-size: cover;
    /* background-image: url('../../../assets/account.png'); */
    background-color: #fff;
    width: 100px;
    height: 100px;
  }

  .titolo.segnalazioni {
    margin-top: 24px;
  }
  .loyalty-events-counter {
    margin:  0 0 16px 0;
  }

  .titolo {
    text-align: center;
  }

  .eventDetail {
    background-color: #375863;
    color: #fff;
    overflow: hidden;
    height: 0;
    transition: height 0.3s ease-out;
    position: relative;

    .material-icons {
      position: absolute;
      top: 16px;
      right: 16px;
      cursor: pointer;
    }

    .content {
      padding: 16px;
      overflow: hidden;
      height: 100%;
      overflow-y: auto;

      .description {
        margin-bottom: 1em;
      }
    }
  }
  .eventDetail.opened {
    height: auto;
  }
  @media screen and (max-width: $break-tablet) {
    h3, h4 {
      text-align: center;
    }
  }
}
</style>
