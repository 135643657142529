import Vue from 'vue'
import moment from 'moment'
import axios from 'axios'
import VueCustomElement from 'vue-custom-element'

import VueGettext from '@paiuolo/pai-vue-gettext/plugins/VueGettext'

import LoyaltyUserProfile from './components/entities/loyalty/user/LoyaltyUserProfile'


axios.defaults.xsrfCookieName = 'csrftoken'
axios.defaults.xsrfHeaderName = 'X-CSRFToken'

Vue.prototype.$http = axios
Vue.config.productionTip = false

Vue.use(VueCustomElement)
Vue.use(VueGettext, {
  callback: function (lang) {
    moment.locale(lang)
  }
})

Vue.customElement('loyalty-user-profile', LoyaltyUserProfile)
